import './sentry';

// Base scripts
import initCarousels from './carousel';
import initCookieAccept from './cookie-accept';
import initDropdowns from './dropdown';
import initFormFileInputs from './form-file-inputs';
import initNotifications from './notification';

// Theme scripts
import initMobileNavigation from './mobile-navigation';

// Plugin scripts
import initAsyncForms from '../../../app/Plugins/Form/resources/js/main';

// Cookie accept
initCookieAccept();

window.addEventListener('DOMContentLoaded', () => {
    initCarousels();
    initFormFileInputs();
    initMobileNavigation();
    initDropdowns();
    initNotifications();
    initAsyncForms();
});

// Tell Vite to build all files in this directory
import.meta.glob(['../img/**', '../fonts/**']);
