const preventMultipleSubmits = () => {
    const forms = Array.from(document.querySelectorAll('form'));

    // Prevent Double Submits
    forms.forEach((form) => {
        form.addEventListener('submit', (e) => {
            // Prevent if already submitting
            if (form.classList.contains('is-submitting')) {
                e.preventDefault();
            }

            // Add class to hook our visual indicator on
            form.classList.add('is-submitting');
        });
    });

    // Remove is-submitting class right before leaving the page,
    // otherwise after using the browser back button the form is still disabled
    window.addEventListener('visibilitychange', () => {
        forms.forEach((form) => {
            form.classList.remove('is-submitting');
        });
    });
};

export { preventMultipleSubmits as default };
