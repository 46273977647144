import initAnimatedToggle from './animated-toggle';

const initMobileNavigation = (
    containerSelector = '[data-mobile-navigation]',
    toggleSelector = '[data-mobile-navigation-toggle]',
    contentSelector = '[data-mobile-navigation-content]',
    backdropSelector = '[data-mobile-navigation-backdrop]'
) => {
    const container = document.querySelector(containerSelector);

    // Animate navigation backdrop on click
    initAnimatedToggle(backdropSelector, toggleSelector, {
        animationClass: 'animate-nav-fade-in',
    });

    // Animate navigation content on click
    initAnimatedToggle(contentSelector, toggleSelector, {
        animationClass: 'animate-nav-pop-in',
        onOpen: () => {
            container.classList.remove('hidden');
        },
        afterClose: () => {
            container.classList.add('hidden');
        },
    });
};

export { initMobileNavigation as default };
