import AsyncForm from './async-form';
import preventMultipleSubmits from './prevent-multiple-submits';

// Initialize the async forms - this should be done after the DOM is loaded
const initAsyncForms = () => {
    window.asyncForms.forEach((form) => {
        new AsyncForm(document, `#${form.id}`, form.options);
    });

    preventMultipleSubmits();
};

export { initAsyncForms as default };
