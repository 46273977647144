import * as Sentry from '@sentry/browser';

if (window.sentry && (process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'production')) {
    Sentry.init({
        dsn: window.sentry.dsn,

        // Only allow errors from project domain to be logged
        allowUrls: window.sentry.allowUrls,
    });
}
