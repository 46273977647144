const setCookie = (key, value, days = 7) => {
    const daysInt = parseInt(days, 10);
    const expires = new Date();

    expires.setTime(expires.getTime() + daysInt * 24 * 60 * 60 * 1000);
    document.cookie = `${key}=${value};expires=${expires.toUTCString()}`;
};

const initNotifications = (attribute = 'data-notification', closeAttribute = 'data-close-notification') => {
    const notifications = Array.from(document.querySelectorAll(`[${attribute}]`));

    notifications.forEach((notification) => {
        const id = notification.getAttribute(attribute);
        const button = notification.querySelector(`[${closeAttribute}]`);

        button.addEventListener('click', (e) => {
            e.preventDefault();

            setCookie(`notification-${id}`, null, 1);

            button.closest('[data-notification]').remove();
        });
    });
};

export { initNotifications as default };
