const niceBytes = (x) => {
    const units = ['bytes', 'KB', 'MB', 'GB'];

    let l = 0;
    let n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
        n /= 1024;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
};

const initFormFileInputs = (
    containerSelector = '[data-input-file]',
    textSelector = '[data-input-file-text]',
    inputSelector = '[data-input-file-input]'
) => {
    const containers = Array.from(document.querySelectorAll(containerSelector));

    containers.forEach((container) => {
        const textEl = container.querySelector(textSelector);
        const inputEl = container.querySelector(inputSelector);

        inputEl.addEventListener('change', (e) => {
            const files = Array.from(e.target.files);
            let output = '';

            files.forEach((file) => {
                output += `${file.name} (${niceBytes(file.size)})<br>`;
            });

            if (output === '') {
                output = textEl.getAttribute('data-file-names');
            }

            textEl.innerHTML = output;
        });
    });
};

export { initFormFileInputs as default };
