class Dropdown {
    constructor(toggleElement, dropdownHasPopupElement, dropdownElement) {
        this.toggleElement = toggleElement;
        this.dropdownHasPopupElement = dropdownHasPopupElement;
        this.dropdownElement = dropdownElement;

        this._init();
    }

    _init() {
        this.toggleElement.addEventListener('mouseover', () => {
            this._open();
        });

        this.toggleElement.addEventListener('mouseout', () => {
            this._close();
        });

        this.dropdownHasPopupElement.addEventListener('focus', () => {
            this._open();
        });

        this.toggleElement.addEventListener('focusout', (e) => {
            // Check if newly focussed element is inside toggle element
            if (!this.toggleElement.contains(e.relatedTarget)) {
                this._close();
            }
        });
    }

    _open() {
        this.dropdownHasPopupElement.setAttribute('aria-expanded', 'true');
        this.dropdownElement.classList.remove('hidden');
    }

    _close() {
        this.dropdownHasPopupElement.setAttribute('aria-expanded', 'false');
        this.dropdownElement.classList.add('hidden');
    }
}

const initDropdowns = function (
    toggleAttribute = 'data-toggle-dropdown',
    hasPopupAttribute = 'data-has-popup',
    dropdownAttribute = 'data-dropdown'
) {
    const toggleElements = Array.from(document.querySelectorAll(`[${toggleAttribute}]`));

    toggleElements.forEach((toggleElement) => {
        const hasPopupElement = document.querySelector(
            `[${hasPopupAttribute}="${toggleElement.getAttribute(toggleAttribute)}"]`
        );
        const dropdownElement = document.querySelector(
            `[${dropdownAttribute}="${toggleElement.getAttribute(toggleAttribute)}"]`
        );

        /* If dropdown exists for this dropdown toggle */
        if (dropdownElement) {
            new Dropdown(toggleElement, hasPopupElement, dropdownElement);
        }
    });
};

export { initDropdowns as default };
