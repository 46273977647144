/**
 * Toggle visibility of element based on a given CSS animation
 * @param {String} containerSelector
 * @param {String} toggleSelector
 */
class AnimatedToggle {
    constructor(container, toggles, options = {}) {
        this.container = container;
        this.toggles = toggles;

        if (!this.container && this.toggles.length === 0) return;

        this.isOpen = false;

        this.onOpen = options.onOpen || function () {};
        this.onClose = options.onClose || function () {};
        this.afterOpen = options.afterOpen || function () {};
        this.afterClose = options.afterClose || function () {};
        this.animationClass = options.animationClass || 'animate-nav-slide-in';

        this._init();
    }

    _init() {
        this.toggles.forEach((toggle) => {
            toggle.addEventListener('click', () => {
                this._toggle();
            });
        });
    }

    open() {
        this._open();

        this.isOpen = true;
    }

    _toggle() {
        if (this.isOpen) {
            this._close();
        } else {
            this._open();
        }

        this.isOpen = !this.isOpen;
    }

    _open() {
        this.onOpen();

        this.container.classList.remove('hidden');

        const onAnimationEnd = () => {
            this.afterOpen();

            this.container.removeEventListener('animationend', onAnimationEnd);
        };

        this.container.addEventListener('animationend', onAnimationEnd);
    }

    _close() {
        this.onClose();

        this.container.style.animationDirection = 'reverse';
        this.container.classList.remove(this.animationClass);
        /* eslint-disable */
        void this.container.offsetWidth;
        /* eslint-enable */
        this.container.classList.add(this.animationClass);

        const onAnimationEnd = () => {
            this.container.classList.add('hidden');
            this.container.style.animationDirection = 'normal';
            this.container.removeEventListener('animationend', onAnimationEnd);

            this.afterClose();
        };

        this.container.addEventListener('animationend', onAnimationEnd);
    }
}

const initAnimatedToggle = (
    containerSelector = '[data-animated-toggle-container]',
    toggleSelector = '[data-animated-toggle]',
    options = {}
) => {
    const container = document.querySelector(containerSelector);
    const toggles = Array.from(document.querySelectorAll(toggleSelector));

    new AnimatedToggle(container, toggles, options);
};

export { initAnimatedToggle as default };
